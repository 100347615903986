import { Poppins, Quicksand,Montserrat } from 'next/font/google'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
const popins =Poppins({weight:"500",subsets:['latin']})
const quickSand =Quicksand({weight:"400",subsets:['latin']})
const monserrat =Montserrat({weight:"400",subsets:['latin']})
export default function Footer() {
  const currentYear = new Date().getFullYear();
const places = [
  {
    district:"Chennai - OMR",
    place:"No.4/643,ADM Tower 2nd Floor,VOC street, OMR, Thoraippakkam,Chennai-600097",
    mobileNumber:"+9178258 88899",
    href:"+917825888899"

  },
  {
    district:"Chennai - Keelkattalai",
    place:"Second Floor, S.V Mini Hall, No:8, 2nd Main Rd, Duraisamy Nagar, Keelkattalai, Chennai-600117",
    mobileNumber:"+91 78258 88844",
    href:"+917825888844"


  },
  {
    district:"Chennai - Ashok Nagar",
    place:"No 32, Ground Floor, 12th Avenue Ashok nagar, chennai - 600083",
    mobileNumber:"+91 78258 88855",
    href:"+917825888855"

  },
  {
    district:"Tirunelveli",
    place:"NO.181A, Masha Allah Complex S.N.High Road,Sripuram Tirunelveli-627007",
    mobileNumber:"+91 78258 88866",
    href:"+917825888866"

  },
]

  return (
    <>
    <footer>
   <section className='footer-bg'>
     <div className='container'>
        <div className='pdt40 df fdc pdb30 mpdt20'>
      {/* 1st Row       */}
       <p className={`fs-33 mfs-24 cw ${popins.className}`}>Our <span className='ylw-clr'> Locations </span> </p>
<div className='df fjsb gap24 mfdc tfdc mt32 mfji'>
  {places.map((items,index)=>{
    return(
   <div className='' key={index}>   
  <div className=''>
        <p className={`fs-16 mfs-15 cw ${popins.className}`}>{items.district}</p>
        <div className='mt12 df gap10 location'>
          <Image src={"/footer-img/location.svg"} width={32} height={32} alt={`location-Icons`} loading='lazy' />
          <p className={`fs-16 mfs-15 fwb400 cw  ${quickSand.className}`}>{items.place}</p>
        </div>
        <div className='mt12 df fac gap10 '>
          <Image src={"/footer-img/phone.svg"} width={32} height={32} alt={`Phone-Icons`} loading='lazy' />
         <Link href={`tel:${items.href}`}> <p className={`fs-16 mfs-15 cw ${quickSand.className}`}>{items.mobileNumber}</p> </Link> 
        </div>
        <div className='mt24 df fac gap10 '>
          <Image src={"/footer-img/wp.svg"} width={32} height={32} alt={`Whatsapp-Icons`} loading='lazy' />
        <Link href={"https://api.whatsapp.com/send/?phone=917825888899&text&type=phone_number&app_absent=0"}>  <p className={`fs-16 mfs-15 cw ${quickSand.className}`}>+91 78258 88899</p> </Link>
        </div>
        <div className='mt24 df fac gap10 '>
          <Image src={"/footer-img/email.svg"} width={32} height={32} alt={`Mail-Icons`} loading='lazy' />
        <Link href={"mailto:contact@learnsoft.org"}>  <p className={`fs-16 mfs-15 cw ${quickSand.className}`}>contact@learnsoft.org</p> </Link>
        </div>
      </div><span className='line-code mt24 dn mdb'></span>
      </div>
  

)
})}
</div>
<span className='line-code mt20 mmt10 mdn'></span>

{/* 2nd Row */}




<div className='df fjsb  mfdc fw '>
<div className='mdn pdt20 mpdt10'>
    <div>
        <Image src={"/footer-img/logo.webp"} alt='LOGO' width={"121"} height={"132"} loading='lazy'/>
    </div>
    <div className=' mdf mfjc mfdc mfac'>
    <p className={`fs-16 mfs-15 cw  para ${quickSand.className}`}>At learnsoft.org, our expert faculty and hands-on training guarantee you acquire practical skills and industry-relevant knowledge to excel in your chosen field.</p>

        

    </div>
    </div>

<div className='mpdt20 pdt130'>

<h6 className={`fs-19 ylw-clr ${popins.className}`}>Company</h6>

<p className={`fs-16 mfs-15 cw pdt8 hvz`}>
<Link href="/blog" className={`cw ${quickSand.className}`}>Blog</Link>
</p>
<p className={`fs-16 mfs-15cw pdt8 hvz`}>
<Link href="/contact" className={`cw ${quickSand.className}`}>Contact</Link>
</p>
<p className={`fs-16 mfs-15cw pdt8 hvz`}>
<Link href="/about" className={`cw ${quickSand.className}`}>About us</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/refund" className={`cw ${quickSand.className}`}>Refund Policy</Link>
</p>


</div>


<div className='mpdt20 pdt130'>
<h6 className={`fs-20 ylw-clr ${popins.className}`}>Cloud</h6>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/aws-training" className={`cw ${quickSand.className}`}>AWS Cloud</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/azure-training" className={`cw ${quickSand.className}`}>Azure Cloud</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/google-cloud-training" className={`cw ${quickSand.className}`}>Google Cloud</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/salesforce-training" className={`cw ${quickSand.className}`}>Salesforce</Link>
</p>
</div>

<div className='mpdt20 pdt130'>
<h6 className={`fs-20 ylw-clr ${popins.className}`}>DevOps</h6>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/jenkins-training" className={`cw ${quickSand.className}`}>Jenkins</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/terraform-training" className={`cw ${quickSand.className}`}>Terraform</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/kubernetes-training" className={`cw ${quickSand.className}`}>Kubernetes</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/devsecops-training" className={`cw ${quickSand.className}`}>DevSecOps</Link>
</p>
</div>

<div className='mpdt20 pdt130'>
<h6 className={`fs-20 ylw-clr ${popins.className}`}>Development</h6>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/ui-ux-design-course" className={`cw ${quickSand.className}`}>UI UX Design</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/reactjs-training" className={`cw ${quickSand.className}`}>ReactJS</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 hvz`}>
<Link href="/training/flutter-training" className={`cw ${quickSand.className}`}>Flutter</Link>
</p>
<p className={`fs-16 mfs-15 fwb400 cw pdt8 .hvz `}>
<Link href="/training/nodejs-training" className={`cw ${quickSand.className}`}>NodeJS</Link>
</p>
</div>
</div>

 {/* 3rd Row */}

<span className='line-code mt24'></span>

<div className='df fdc fac fjc'>
  <div className='df pdt41 mpdt20 gap16'>
    <div>
<Link legacyBehavior href={"https://www.instagram.com/learnsoft_org/"} target="_blank">
          <a target='blank'>
          <Image src={"/footer-img/insta.webp"} alt='Insta-Icon' width={"46"} height={"47"} loading='lazy'/>
          </a></Link>
    </div>
    <div>
<Link legacyBehavior href={"https://www.facebook.com/profile.php?id=100090640644778&mibextid=LQQJ4d"}>
<a target='blank'>
         <Image src={"/footer-img/fb.webp"} alt='Fb-Icon' width={"46"} height={"47"} loading='lazy'/>
      </a>
      </Link>  
    </div>
    <div>
<Link legacyBehavior href={"https://x.com/Learnsoftorg"}>
<a target='blank'>
        <Image src={"/footer-img/x.webp"} alt='X-Icon' width={"46"} height={"47"} loading='lazy'/>
      </a>
      </Link>
    </div>
    <div>
<Link legacyBehavior href={"https://www.linkedin.com/in/learnsoft-organization-it-training-internship-and-placement?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"}>
<a target='blank'>
         <Image src={"/footer-img/linkedin.webp"} alt='Linkedin-Icon' width={"46"} height={"47"} loading='lazy'/>
</a>
</Link>
    </div>

  </div>
  <div className={`cw  pdt32 mpdt20`}> 
    
     <p className={`fs-14 cw tac pdt5 ${quickSand.className}`}>  &copy; {currentYear} learnsoft.org  All Rights Reserved</p>
  </div>
</div>
    </div>
     </div>
   </section>
   </footer>
<style jsx>

    {
        `
      .footer-bg{
        // background-image: url('/footer-img/footer-bg.webp');
        background-color:#000;
        width:100%;
        height: auto;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;
        //
        // background-size: 100% 100%;
      }  
      .hvz:hover {
        color: #ffa900;
      }
      .location{
      height: 100px;
      }
         .vert-code{
        background: linear-gradient(90deg, rgba(255, 169, 0, 0.1) 0%, #FFA900 50%, rgba(255, 169, 0, 0.1) 100%);
        width:3px;
        height: 230px;
      }
      .line-code{
        background: linear-gradient(90deg, rgba(255, 169, 0, 0.1) 0%, #FFA900 50%, rgba(255, 169, 0, 0.1) 100%);
        width:100%;
        height: 3px;
      }
      .para{
        width: 270px;
      }
      @media only screen and (max-width: 1023px){
        .footer-bg{
            background-color:#000000;
              width:100%;
            height: auto;

    
          }  

               .location{
      height: auto;
      }
      }
        
       @media only screen and (max-width: 1250px){
           .tfdc{
              display: flex;
              flex-direction: column;
              }
       } 
        
        
        `
    }
</style>




   </>

  )
}
