import { Poppins } from 'next/font/google';
import Image from 'next/image'
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FaChevronDown } from "react-icons/fa";
import React, { useState } from 'react';
import { motion } from 'framer-motion';
 
const poppins = Poppins({ weight: '500', subsets: ['latin'] });
const extraPoppins = Poppins({ weight: '700', subsets: ['latin'] });
 
export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const router = useRouter(); // Getting the current route
 
  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
 
  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
 
  return (
    <>
      <section className='navbar-bg'>
        <div className='container'>
          <div className='df fac fjsb'>
            {/* Header Logo */}
            <div className='mob-logo'>
              <div className='df fac gap16'>
                <Link href={"/"}>
                  <Image src={"/header/logo.webp"} width={77} height={81} alt='Learnsot.org-Logo' loading='lazy' />
                </Link>
                <Link href={"/"}>
                  <div>
                    <p className={`fs-30 mfs-19 ylw-clr ${extraPoppins.className}`}>learnsoft.org</p>
                    <p className={`cw fs-12 mfs-7 pdt3 ${poppins.className}`}>Training | Internship | Placement</p>
                  </div>
                </Link>
              </div>
            </div>
 
            {/* Burger Icon */}
            <div className={`burger cursor ${isMobileMenuOpen ? 'hidden' : ''}`} onClick={handleMobileMenu}>
              <div>
                <div className="df">
                  <div className="bar-1"></div>
                  <div className="bar-2"></div>
                </div>
                <div className="bar" style={{ marginTop: "4px" }}></div>
                <div className="df" style={{ marginTop: "4px" }}>
                  <div className="bar-3"></div>
                  <div className="bar-1"></div>
                </div>
              </div>
            </div>
 
            {/* Header Menu */}
            <div className={`df gap46 des`}>
              <Link href="/">
                <h6 className={`fs-19 cw ${poppins.className} ${router.pathname === '/' ? "active" : ""}`}>Learnsoft</h6>
              </Link>
              <Link href="/course">
                <h6 className={`fs-19 cw ${poppins.className}  ${router.pathname === '/course' ? "active" : ""}`}>Courses</h6>
              </Link>
              <div className='pos-r'>
                <div className='df fac gap5'>
                  <h6 onClick={handleDropdown} className={`fs-19 cw cursor ${poppins.className}`}>Placement & Resources</h6>
                  <motion.div
                    animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}>
                    <FaChevronDown size={16} color='#FFFFFF' />
                  </motion.div>
                </div>
                {isDropdownOpen && (
                  <motion.div className='drop-down cursor'
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                 <Link href={"/it-placement-consultants-in-chennai"} >
                   <p className={`fs-19 pdtbl10 ${poppins.className}`}>Placement</p>
                   </Link>
                   <Link href={"/corporate-training"} >
                    <p className={`fs-19 pdtbl10 ${poppins.className}`}>Corporate</p>
                    </Link>
                    <Link href={"/master"} >
                    <p className={`fs-19 pdtbl10 ${poppins.className}`}>Masters</p>
                    </Link>
                    <Link href={"/event/how-to-develop-ecommerce-website"} >
                    <p className={`fs-19 pdtbl10 ${poppins.className}`}>Event</p>
                    </Link>
                    <Link href={"/blog"} >
                    <p className={`fs-19 pdtbl10 ${poppins.className}`}>Blogs</p>
                    </Link>

                    </motion.div>
                )}
              </div>
              <Link href="/about">
                <h6 className={`fs-19 cw ${poppins.className} ${router.pathname === '/about' ? "active" : ""}`}>About Us</h6>
              </Link>
              <Link href="/contact">
                <h6 className={`fs-19 cw ${poppins.className} ${router.pathname === '/contact' ? "active" : ""}`}>Contact Us</h6>
              </Link>
            </div>
          </div>
        </div>
 
        {/* Mobile Device Nav Bar */}
        <div className={`mobile-nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <div className='df fac fjsb'>
          <div className='mob-logo'>
              <div className='df fac gap16'>
                <Link href={"/"}>
                  <Image src={"/header/logo.webp"} width={47} height={51} alt='Learnsot.org-Logo' loading='lazy' />
                </Link>
                <Link href={"/"}>
                  <div>
                    <p className={`fs-30 mfs-19 ylw-clr ${extraPoppins.className}`}>learnsoft.org</p>
                    <p className={`cw fs-12 mfs-7 pdt3 ${poppins.className}`}>Training | Internship | Placement</p>
                  </div>
                </Link>
              </div>
            </div>
            <p className='close cw fs-24' onClick={handleMobileMenu}>X</p>
          </div>
          {isMobileMenuOpen && (
            <div className='mt36'>
                <Link href="/">
                <p className={`fs-19 cw ${poppins.className} `}>Learnsoft</p>
              </Link>
              <p className='line-code '></p>
              <Link href="/course ">
                <p className={`fs-19 cw pdt24 ${poppins.className}`}>Course</p>
              </Link>
              <p className='line-code '></p>
              <div className='df fac gap5 pdt24' onClick={() => handleDropdown()}>
                <p className='fs20 fw7 lnh24 cw  '>Placement & Resources</p>
                <motion.div
                    animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}>
                    <FaChevronDown size={16} color='#FFFFFF' className=''/>
                  </motion.div>
              </div>
            {!isDropdownOpen &&  <p className='line-code '></p> }
              {isDropdownOpen && (
                <div className='mt27 '>
                   <Link href={"/it-placement-consultants-in-chennai"}>
                  <p className={`fs-19 pdtbl10 drop  ${poppins.className}`}>Placement</p>
                  </Link>
                  <Link href={"/corporate-training"}>
                  <p className={`fs-19 pdtbl10 drop  ${poppins.className}`}>Corporate</p>
                  </Link>
                  <Link href={"/master"} >
                    <p className={`fs-19 pdtbl10 drop ${poppins.className}`}>Masters</p>
                    </Link>
                  <Link href={"/event/how-to-develop-ecommerce-website"}>
                  <p className={`fs-19 pdtbl10 drop ${poppins.className}`}>Event</p>
                  </Link>
                  <Link href={"/blog"}>
                  <p className={`fs-19 pdtbl10 drop ${poppins.className}`}>Blogs</p>
                  </Link>
                  <p className='line-code '></p>
                </div>
               
              )}

                <Link href="/about  ">
                <p className={`fs-19 cw pdt24 ${poppins.className}`}>About Us</p>
              </Link>
              <p className='line-code '></p>
 
                <Link href="/contact"><p className='fs20 fw7 lnh24 cw pdt24'>Contact Us</p></Link>
                <p className='line-code '></p>
            </div>
          )}
          {/* Media Icons */}

<div className='media-icons df fjc fac gap8'>

<div>
<Image src={"/header/insta-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/fb-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/x-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/linkedin-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>


</div>
        </div>
      </section>
      <style jsx>
        {`
          .navbar-bg {
            background-color: #000;
            width: 100%;
            padding: 15px 0;
            // position: sticky;
            // top: 0px;
            // z-index: 999;
          }
         .hidden{
         display: none !important;
         }
          .mobile-nav {
            display: none;
          }
 
          h6 {
            position: relative;
          }
 
          h6::before {
            content: '';
            position: absolute;
            bottom: -24px;
            left: 0;
            width: 0;
            height: 3px;
            background-color: #ffbd50;
            transition: all 0.5s;
          }
 
          h6:hover::before {
            width: 100%;
          }
 
          .active::before {
            width: 100%;
          }
 
          .active {
            color: #ffbd50;
          }
 
          h6:hover {
            color: #ffbd50;
          }
 
          p {
            transition: all 0.4s ease-in-out;
          }
 
          .burger {
            display: none;
            padding-right: 20px;
          }
 
          .bar {
            width: 39px;
            height: 4px;
            background-color: #FFA900;
            margin: 5px 0;
            transition: 0.4s;
            border-radius: 3px;
          }
 
          .bar-1 {
            width: 10px;
            height: 4px;
            background-color: #FFA900;
            border-radius: 3px;
          }
 
          .bar-2 {
            width: 22px;
            height: 4px;
            background-color: #FFA900;
            border-radius: 3px;
            margin-left: 7px;
          }
 
          .bar-3 {
            width: 22px;
            height: 4px;
            background-color: #FFA900;
            border-radius: 3px;
            margin-right: 7px;
          }
 
          @media only screen and (max-width: 1250px) {
                    .navbar-bg {
            background-color: #000;
            width: 100%;
            padding: 10px 0;
            // position: sticky;
            // top: 0;
            z-index: 9999;
          }
            .des {
              display: none;
            }
               .line-code {
                width: 256px;
                border: 1px solid #666666;
                background-color: #666666;
              }
            .mobile-nav {
              display: block;
              position: fixed;
              top: 0;
              left: -100%;
              width: 280px;
              height: 100%;
              background-color: #281E1E;
              transition: left 0.3s ease-in-out;
              z-index: 9999;
              overflow-x: hidden;
              padding: 10px;
              
            }
 
            .mobile-nav.open {
              left: 0;
              
            }
 
            .burger {
              display: flex;
            }
 
            ::-webkit-scrollbar {
              display: none;
            }
                      .media-icons{
                   width: 90%;
                  height: 96px;
                  background-color: #FAA21A;
                  position: absolute;
                  bottom: 0px;
                }
          }
        `}
      </style>
    </>
  );
}